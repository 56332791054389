import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "searchInput", "checkbox"];
  static values = {
    url: String,
    context: String,
  };

  connect() {
    // Close dropdown when clicking outside
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.boundHandleClickOutside);
    // Handle window resize
    this.boundPositionDropdown = this.positionDropdown.bind(this);
    window.addEventListener("resize", this.boundPositionDropdown);
    window.addEventListener("scroll", this.boundPositionDropdown);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside);
    window.removeEventListener("resize", this.boundPositionDropdown);
    window.removeEventListener("scroll", this.boundPositionDropdown);
  }

  toggle(event) {
    event.stopPropagation();
    this.contentTarget.classList.toggle("hidden");

    if (!this.contentTarget.classList.contains("hidden")) {
      this.positionDropdown();
    }
  }

  positionDropdown() {
    const button = this.element.querySelector("button");
    const buttonRect = button.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;

    // Position the dropdown below the button
    this.contentTarget.style.top = `${buttonRect.bottom + scrollTop}px`;
    this.contentTarget.style.left = `${buttonRect.left + scrollLeft}px`;

    // Check if dropdown would go off screen
    const dropdownRect = this.contentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    // If dropdown would go off the right side of the screen
    if (dropdownRect.right > viewportWidth) {
      const adjustment = dropdownRect.right - viewportWidth + 10; // 10px padding
      this.contentTarget.style.left = `${
        buttonRect.left + scrollLeft - adjustment
      }px`;
    }
  }

  closeBackground(event) {
    // Only close if clicking outside the dropdown
    if (!this.element.contains(event.target)) {
      this.contentTarget.classList.add("hidden");
    }
  }

  handleClickOutside(event) {
    this.closeBackground(event);
  }

  search(event) {
    const query = event.target.value.toLowerCase();
    this.checkboxTargets.forEach((checkbox) => {
      const label = checkbox.nextElementSibling;
      const text = label.textContent.toLowerCase();
      const container = checkbox.closest("div");

      if (text.includes(query)) {
        container.classList.remove("hidden");
      } else {
        container.classList.add("hidden");
      }
    });
  }

  clear() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.searchInputTarget.value = "";
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.closest("div").classList.remove("hidden");
    });
  }

  clearDates() {
    const dateInputs = this.element.querySelectorAll('input[type="date"]');
    dateInputs.forEach((input) => {
      input.value = "";
    });
    this.contentTarget.classList.add("hidden");
  }
}
