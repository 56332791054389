// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus";
import "@gorails/ninja-keys";
import autoComplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {
  connect() {
    this.element.data = [
      {
        id: "CustomerLedger",
        title: "Customer Ledger",
        hotkey: "ctrl+L",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>',
        handler: async () => {
          console.log("CustomerLedger");

          // Cleanup any existing modals
          const existingModal = document.querySelector(
            ".customer-ledger-modal"
          );
          if (existingModal) {
            existingModal.remove();
          }

          // Create a unique ID for this instance
          const uniqueId = `autoComplete-customer-${Date.now()}`;

          // Create a container for autoComplete.js
          const searchContainer = document.createElement("div");
          searchContainer.className = "w-full mb-4";

          // Add a title to the modal
          const titleContainer = document.createElement("div");
          titleContainer.className = "mb-4 flex items-center justify-between";

          const title = document.createElement("h3");
          title.className = "text-lg font-medium text-gray-900";
          title.textContent = "Customer Ledger";

          const closeButton = document.createElement("button");
          closeButton.className =
            "text-gray-500 hover:text-gray-700 focus:outline-none";
          closeButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          `;

          titleContainer.appendChild(title);
          titleContainer.appendChild(closeButton);

          // Create the search input for autoComplete.js
          const searchInput = document.createElement("input");
          searchInput.type = "text";
          searchInput.id = uniqueId;
          searchInput.placeholder = "Search customer accounts...";
          searchInput.className =
            "w-full p-3 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50";
          searchContainer.appendChild(searchInput);

          // Create a results container
          const resultsContainer = document.createElement("div");
          resultsContainer.className =
            "mt-2 max-h-60 overflow-y-auto rounded-md border border-gray-200";

          // Create a modal container
          const modalContainer = document.createElement("div");
          modalContainer.className =
            "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 customer-ledger-modal";
          modalContainer.style.display = "none";

          // Create the modal content
          const modalContent = document.createElement("div");
          modalContent.className = "bg-white rounded-lg p-6 w-96 shadow-xl";
          modalContent.appendChild(titleContainer);
          modalContent.appendChild(searchContainer);
          modalContent.appendChild(resultsContainer);
          modalContainer.appendChild(modalContent);

          // Define a cleanup function for the modal
          const cleanupModal = () => {
            modalContainer.remove();

            // Remove event listeners
            document.removeEventListener("keydown", escapeHandler);

            // Clear any AutoComplete instance
            if (autoCompleteJS && typeof autoCompleteJS.unInit === "function") {
              autoCompleteJS.unInit();
            }
          };

          // Add event listener to close button
          closeButton.addEventListener("click", cleanupModal);

          // Add the modal to the document
          document.body.appendChild(modalContainer);

          // Show the modal
          modalContainer.style.display = "flex";

          // Initialize autoComplete.js
          const autoCompleteJS = new autoComplete({
            selector: `#${uniqueId}`,
            placeHolder: "Search customer accounts...",
            data: {
              src: async (query) => {
                try {
                  // Show loading indicator
                  resultsContainer.innerHTML = `
                    <div class="p-3 text-sm text-gray-500 flex items-center justify-center">
                      <svg class="animate-spin h-5 w-5 mr-2 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Searching...
                    </div>
                  `;

                  const response = await fetch(
                    `/customer_accounts/search?q=${encodeURIComponent(query)}`,
                    {
                      headers: {
                        Accept: "application/json",
                        "X-CSRF-Token": document.querySelector(
                          'meta[name="csrf-token"]'
                        )?.content,
                      },
                    }
                  );

                  if (!response.ok) throw new Error("Search failed");

                  // Clear loading indicator
                  resultsContainer.innerHTML = "";

                  return await response.json();
                } catch (error) {
                  console.error("Search failed:", error);
                  // Show error message
                  resultsContainer.innerHTML = `
                    <div class="p-3 text-sm text-red-500 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                      Search failed. Please try again.
                    </div>
                  `;
                  return [];
                }
              },
              keys: ["name"],
              cache: false,
            },
            resultsList: {
              element: (list, data) => {
                if (!data.results.length) {
                  const message = document.createElement("div");
                  message.className =
                    "p-3 text-sm text-gray-500 flex items-center justify-center";
                  message.innerHTML = `
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    No customers found
                  `;
                  list.appendChild(message);
                }
              },
              noResults: true,
            },
            resultItem: {
              element: (item, data) => {
                item.className =
                  "p-3 hover:bg-gray-100 cursor-pointer flex items-center border-b border-gray-100 last:border-b-0";
                item.innerHTML = `
                  <span class="font-medium">${data.value.name}</span>
                `;
              },
              highlight: true,
            },
            events: {
              input: {
                focus() {
                  // Optional: You can perform actions when the input is focused
                },
              },
            },
          });

          autoCompleteJS.input.addEventListener("selection", function (event) {
            console.log(event.detail.selection.value);
            const selectedCustomer = event.detail.selection.value;
            Turbo.visit(
              `/reports/customers/ledger?q%5Bfrom_account_id_or_to_account_id_in%5D=${selectedCustomer.id}`
            );
            cleanupModal();
          });

          // Close modal when clicking outside
          modalContainer.addEventListener("click", (e) => {
            if (e.target === modalContainer) {
              cleanupModal();
            }
          });

          // Add keyboard event listener to close on escape
          function escapeHandler(e) {
            if (e.key === "Escape") {
              cleanupModal();
            }
          }
          document.addEventListener("keydown", escapeHandler);

          // Focus the search input
          searchInput.focus();
        },
      },
      {
        id: "ItemDetailReport",
        title: "Item Detail Report",
        hotkey: "ctrl+I",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>',
        handler: async () => {
          console.log("ItemDetailReport");

          // Cleanup any existing modals
          const existingModal = document.querySelector(
            ".item-detail-report-modal"
          );
          if (existingModal) {
            existingModal.remove();
          }

          // Create a unique ID for this instance
          const uniqueId = `autoComplete-${Date.now()}`;

          // Create a container for autoComplete.js
          const searchContainer = document.createElement("div");
          searchContainer.className = "w-full mb-4";

          // Add a title to the modal
          const titleContainer = document.createElement("div");
          titleContainer.className = "mb-4 flex items-center justify-between";

          const title = document.createElement("h3");
          title.className = "text-lg font-medium text-gray-900";
          title.textContent = "Item detail report";

          const closeButton = document.createElement("button");
          closeButton.className =
            "text-gray-500 hover:text-gray-700 focus:outline-none";
          closeButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          `;

          titleContainer.appendChild(title);
          titleContainer.appendChild(closeButton);

          // Create the search input for autoComplete.js
          const searchInput = document.createElement("input");
          searchInput.type = "text";
          searchInput.id = uniqueId;
          searchInput.placeholder = "Search items...";
          searchInput.className =
            "w-full p-3 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50";
          searchContainer.appendChild(searchInput);

          // Create a results container
          const resultsContainer = document.createElement("div");
          resultsContainer.className =
            "mt-2 max-h-60 overflow-y-auto rounded-md border border-gray-200";

          // Create a modal container
          const modalContainer = document.createElement("div");
          modalContainer.className =
            "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 item-detail-report-modal";
          modalContainer.style.display = "none";

          // Create the modal content
          const modalContent = document.createElement("div");
          modalContent.className = "bg-white rounded-lg p-6 w-96 shadow-xl";
          modalContent.appendChild(titleContainer);
          modalContent.appendChild(searchContainer);
          modalContent.appendChild(resultsContainer);
          modalContainer.appendChild(modalContent);

          // Define a cleanup function for the modal
          const cleanupModal = () => {
            modalContainer.remove();

            // Remove event listeners
            document.removeEventListener("keydown", escapeHandler);

            // Clear any AutoComplete instance
            if (autoCompleteJS && typeof autoCompleteJS.unInit === "function") {
              autoCompleteJS.unInit();
            }
          };

          // Add event listener to close button
          closeButton.addEventListener("click", cleanupModal);

          // Add the modal to the document
          document.body.appendChild(modalContainer);

          // Show the modal
          modalContainer.style.display = "flex";

          // Initialize autoComplete.js
          const autoCompleteJS = new autoComplete({
            selector: `#${uniqueId}`,
            placeHolder: "Search items...",
            data: {
              src: async (query) => {
                try {
                  // Show loading indicator
                  resultsContainer.innerHTML = `
                    <div class="p-3 text-sm text-gray-500 flex items-center justify-center">
                      <svg class="animate-spin h-5 w-5 mr-2 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Searching...
                    </div>
                  `;

                  const response = await fetch(
                    `/items/search?q=${encodeURIComponent(query)}`,
                    {
                      headers: {
                        Accept: "application/json",
                        "X-CSRF-Token": document.querySelector(
                          'meta[name="csrf-token"]'
                        )?.content,
                      },
                    }
                  );

                  if (!response.ok) throw new Error("Search failed");

                  // Clear loading indicator
                  resultsContainer.innerHTML = "";

                  return await response.json();
                } catch (error) {
                  console.error("Search failed:", error);
                  // Show error message
                  resultsContainer.innerHTML = `
                    <div class="p-3 text-sm text-red-500 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                      Search failed. Please try again.
                    </div>
                  `;
                  return [];
                }
              },
              keys: ["name"],
              cache: false,
            },
            resultsList: {
              element: (list, data) => {
                if (!data.results.length) {
                  const message = document.createElement("div");
                  message.className =
                    "p-3 text-sm text-gray-500 flex items-center justify-center";
                  message.innerHTML = `
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    No items found
                  `;
                  list.appendChild(message);
                }
              },
              noResults: true,
            },
            resultItem: {
              element: (item, data) => {
                item.className =
                  "p-3 hover:bg-gray-100 cursor-pointer flex items-center border-b border-gray-100 last:border-b-0";
                item.innerHTML = `
                  <span class="font-medium">${data.value.name}</span>
                `;
              },
              highlight: true,
            },
            events: {
              input: {
                focus() {
                  // Optional: You can perform actions when the input is focused
                },
              },
            },
          });

          autoCompleteJS.input.addEventListener("selection", function (event) {
            console.log(event.detail.selection.value);
            Turbo.visit(
              `/reports/items/details?q%5Bitem_id_in%5D=${event.detail.selection.value.id}`
            );
            cleanupModal();
          });

          // Close modal when clicking outside
          modalContainer.addEventListener("click", (e) => {
            if (e.target === modalContainer) {
              cleanupModal();
            }
          });

          // Add keyboard event listener to close on escape
          function escapeHandler(e) {
            if (e.key === "Escape") {
              cleanupModal();
            }
          }
          document.addEventListener("keydown", escapeHandler);

          // Focus the search input
          searchInput.focus();
        },
      },
    ];
  }
}
