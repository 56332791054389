import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "results", "checkbox"];
  static values = {
    url: String,
    searchParam: String,
    resultTemplate: String,
  };

  initialize() {
    this.debouncedSearch = this.debounce(this.performSearch.bind(this), 300);
    this.handleTabKey = this.handleTabKey.bind(this);
  }

  connect() {
    // Initialize empty state
    this.lastQuery = "";
    this.isLoading = false;

    // Add event listener for Tab key
    document.addEventListener("keydown", this.handleTabKey);
  }

  disconnect() {
    // Clean up event listener
    document.removeEventListener("keydown", this.handleTabKey);
  }

  handleTabKey(event) {
    if (event.key === "Tab" && !event.shiftKey) {
      const searchInput = this.searchInputTarget;
      const activeElement = document.activeElement;

      // Only handle Tab if we're not already focused on the search input
      if (activeElement !== searchInput) {
        event.preventDefault();

        // Find and show the dropdown container
        const dropdownContainer = this.element.closest(
          '[data-data-table-filter-target="content"]'
        );
        if (dropdownContainer) {
          dropdownContainer.classList.remove("hidden");
          dropdownContainer.style.display = "block";
        }

        // Focus the search input
        searchInput.focus();

        // Show results div even if empty
        this.resultsTarget.style.display = "block";

        // If there's a value, trigger search
        if (searchInput.value.trim().length >= 2) {
          this.performSearch(searchInput.value.trim());
        }
      }
    }
  }

  // Triggered when user types in search input
  search(event) {
    const query = event.target.value.trim();
    if (query === this.lastQuery) return;

    this.lastQuery = query;
    if (query.length >= 2) {
      this.debouncedSearch(query);
    } else {
      this.resultsTarget.innerHTML = "";
    }
  }

  // Perform the actual API search
  async performSearch(query) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await fetch(
        `${this.urlValue}/search?${this.searchParamValue}=${encodeURIComponent(
          query
        )}`,
        {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              ?.content,
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      this.renderResults(data);
    } catch (error) {
      console.error("Search failed:", error);
      this.resultsTarget.innerHTML =
        '<div class="text-red-600 text-sm p-2">Search failed. Please try again.</div>';
    } finally {
      this.isLoading = false;
    }
  }

  // Render the search results using the template
  renderResults(data) {
    if (!data || data.length === 0) {
      this.resultsTarget.innerHTML =
        '<div class="text-gray-500 text-sm p-2">No results found</div>';
      return;
    }

    const template = document.getElementById(this.resultTemplateValue);
    if (!template) {
      console.error(`Template with id '${this.resultTemplateValue}' not found`);
      return;
    }

    const resultsHtml = data.map((item) => {
      const clone = template.content.cloneNode(true);
      const elements = clone.querySelectorAll("[data-template-bind]");

      elements.forEach((element) => {
        const field = element.getAttribute("data-template-bind");
        if (element.tagName === "INPUT") {
          element.value = item.id;
          element.id = `${element.id}_${item.id}`;
          const label = clone.querySelector(
            `label[for="${element.id.split("_")[0]}"]`
          );
          if (label) label.setAttribute("for", element.id);
        } else if (field === "href") {
          element.href = `${this.urlValue}/${item.id}`;
        } else {
          element.textContent = item[field];
        }
      });

      return clone;
    });

    this.resultsTarget.innerHTML = "";
    resultsHtml.forEach((html) => this.resultsTarget.appendChild(html));
  }

  // Utility function to debounce the search
  debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
}
