import { Controller } from "stimulus";
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";

export default class extends Controller {
  static targets = ["table", "reports"];

  connect() {
    this.setupTable();
  }

  setupTable() {
    let opts = {
      paging: false,
      ordering: false,
      info: false,
      searching: true,
      scrollX: true,
      scrollY: "80vh",
      deferRender: true,
      scrollCollapse: true,
      language: {
        emptyTable: "No data available in table",
      },
    };

    if (this.hasReportsTarget) {
      const reportsdata = $(this.reportsTarget).data(
        "datatable-description-value"
      );
      opts["buttons"] = [
        "copy",
        "excel",
        { extend: "csv" },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
            stripHtml: false,
            format: {
              body: function (inner, rowidx, colidx, node) {
                if (node.classList.contains("font-bold")) {
                  return (
                    '<span class="font-bold" style="font-weight: 700">' +
                    inner +
                    "</span>"
                  );
                } else {
                  return inner;
                }
              },
            },
          },

          customize: function (win) {
            $(win.document.body).prepend(reportsdata);

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "10px");

            // remove first h1 tag in the body
            $(win.document.body).find("h1").remove();
            // Adding CSS directly for borders around table cells
            $(win.document.body)
              .find("table thead tr th, table tbody tr td")
              .css({
                border: "1px solid #494F55",
                "border-collapse": "collapse",
                padding: "3px",
              });

            $(win.document.body).find("table").css({
              "margin-top": "20px",
              "border-collapse": "collapse",
              color: "black",
            });
          },
        },
      ];
      opts["dom"] = "Bfrtiq";
      $(this.reportsTarget).DataTable(opts);
    } else {
      opts["dom"] = "frtiq";
      console.log($.fn.dataTable.isDataTable(".dataTable"));
      if (!$.fn.dataTable.isDataTable(".dataTable")) {
        $(this.tableTarget).DataTable(opts).columns.adjust().draw();
      }
    }
    setTimeout(function () {
      $(window).trigger("resize");
    }, 50);
  }
}
